$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.c-stepRules {
  $width: 50px;
  $height: $width / 2.2;
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  &__valueContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
  &__rulesText {
    font-size: 18px;
    line-height: 22px;
    color: $darker-gray;
    margin: 8px 0;
  }
  &__container {
    display: inline-block;
    margin: (($height * 1.4 - $height) / 2) + 5px;
    margin: (($height * 1.5 - $height) / 2);
    width: $width;
    height: $height;
    text-align: center;
    position: relative;
  }
  &__label {
    font-size: 18px;
    color: $darker-gray;
    cursor: default;
    &:hover {
      color: #7e72b1;
    }
  }
}
