$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.c-selectInput {
  position: relative;
  &__label {
    font-size: 14px;
    font-family: "Poppins";
    color: rgba(0, 0, 0, 0.85);
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.Select {
  position: relative;
  &-control {
    border: none;
    padding-bottom: 10px;
    margin-top: 14px;
    margin-bottom: -1px;
    outline: none;
    box-shadow: none !important;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: $gray;
      border-radius: 1px;
      position: absolute;
      bottom: 1px;
      left: 0;
    }
  }
  &-placeholder {
    font-size: 16px;
    color: $gray;
    font-family: "Lato";
  }
  &-value {
    padding-left: 0 !important;
    &-label {
      font-size: 16px;
      color: $primary-darker;
      display: inline-block;
      margin-top: 7px;
    }
  }
  &-input {
    font-size: 16px;
    color: $gray;
    font-family: "Lato";
    margin-top: 7px;
    margin-bottom: -7px;
    margin-left: -10px;
  }
}

.Select--multi {
  .Select-value {
    background-color: #1890ff;
    color: $white;
    line-height: 20px;
    margin-top: 9px;
    margin-bottom: -3px;
    &-label {
      color: $white;
      margin-top: 0;
      font-family: Poppins;
      font-size: 12px;
    }
  }
}
