$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.c-unAuthAppBox {
  margin-top: 25vh;
  background-color: $white;
  width: 500px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: fit-content;
  border-radius: 3px;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 0;
    margin: 20px;
    padding: 40px 14px;
    justify-content: flex-start;
  }
  > .c-textInput {
    margin-bottom: 22px;
  }
}
