$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.modalShadow {
  display: none;
  &--visible {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9995;
  }
}
.kmodal {
  background-color: white;
  position: fixed;
  top: 6vh;
  opacity: 1;
  transition: top 0.7s, opacity 0.5s;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 440px;
  min-height: 100px;
  z-index: 9999;
  border-radius: 3px;
  overflow: hidden;

  &--thin {
    .kmodal {
      width: 360px;
    }
  }
  &__header {
    width: 100%;
    min-height: 50px;
    padding: 14px 20px;
    font-family: Poppins;
    border-bottom: 1px solid #ddd;
    background-color: $primary;
    color: $white;
    font-size: 20px;
    &--closeButton {
      padding-right: 40px;
    }
  }
  &__closeIcon {
    position: absolute;
    right: 13px;
    top: 13px;
    float: right;
    fill: $white;
    &:hover {
      color: #ddd;
      cursor: pointer;
    }
  }

  &__body {
    width: 100%;
    max-height: 70vh;
    overflow: auto;
    padding: 12px 20px;
  }
  &__footer {
    width: 100%;
    padding: 12px 20px 16px;
    overflow: auto;
  }
  //&__errorSummary {
  //  color: $red;
  //  margin-bottom: 10px;
  //  text-align: center;
  //  font-size: $ts14;
  //  text-decoration: underline;
  //}
  &__wrapper {
    &--entering {
      .kmodal {
        top: 0;
        opacity: 0;
        transition: top 0.3s, opacity 0.2s;
      }
    }

    &--step {
      .kmodal {
        width: 530px;
      }
    }
  }
  &__repeatButton {
    font-size: 18px;
    color: $primary-dark;

    &:hover {
      color: $primary;
    }
  }
}
