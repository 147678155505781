$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.cc-stepRulesModal {
  .kmodal {
    width: 575px;
  }
  .Select-control {
    margin-top: 0;
  }
  .Select-clear {
    display: none;
  }
  .Select-value {
    text-align: center;
    padding-right: 0 !important;
  }
  .Select-value-label {
    font-size: 21px;
  }
  &__contentWrapper {
    min-height: 400px;
  }
  &__rule {
    display: flex;
    justify-content: space-between;
    height: 55px;
    align-items: center;
    border-bottom: 1px solid $gray;
  }
  &__stepSelect {
    width: 80px;
    margin: 0 5px 0 10px !important;
  }
  &__ruleSelect {
    width: 150px;
    margin: 0 5px !important;
  }
  &__valueInput {
    height: 32px;
    margin-bottom: -8px;
    border: none;
    border-bottom: 2px solid #c1c6d5;
    font-size: 21px;
    width: 180px;
    margin: 0 5px !important;
  }
  &__label {
    //margin-bottom: -10px;
    font-size: 18px;
  }
  &__newRuleButtonContainer {
    display: flex;
    justify-content: center;
  }
  &__newRuleButton {
    display: inline-block;
    background-color: $primary;
    height: 48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    cursor: pointer;
    font-size: 31px;
  }
  &__deleteIcon {
    fill: #666;
    cursor: pointer;
    &:hover {
      fill: $primary;
    }
  }
}

.stepScoreRule {
  padding: 10px 14px;
  background-color: #f8f9ff;
  border-radius: 3px;
  position: relative;

  &__label {
    font-size: 18px;
    color: #031b4d;
    display: inline-block;
    vertical-align: center;
    line-height: 40px;
  }
  &__valueInput {
    border: 1px solid #ddd;
    line-height: 26px;
    border-radius: 3px;
    padding: 3px 7px;
    //margin: 0;
    vertical-align: middle;
    box-sizing: border-box;
    outline: none;
    color: #333333;
    font-size: 16px;
    width: 150px;
  }

  &__deleteIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    display: inline-block;
    fill: #666;
  }

  .Select {
    position: relative;
    display: inline-block;
    width: 100px;
    vertical-align: middle;
    margin: 0 5px;
  }

  .Select-value {
    text-align: left;
    margin-left: 10px;
  }
  .Select-control {
    height: 34px;
    border-radius: 3px;
    padding: 0;
    border: 1px solid #ddd;

    &:after {
      display: none;
    }
  }
  .Select-value-label {
    line-height: 34px;
    margin: 0;
    vertical-align: middle;
    font-size: 16px;
  }
  .Select-arrow-zone {
    padding-top: 0;
    padding-bottom: 5px;
  }
  .Select-input {
    margin-top: 0;
  }
  .Select.is-focused:not(.is-open) > .Select-control {
    border-color: #aaa;
  }
  .Select--width2 {
    width: 150px;
  }
}
