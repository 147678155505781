$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.c-button {
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 45px;
  text-align: center;
  padding: 0 1.5rem;
  min-width: 100%;
  background-color: $red;
  font-family: "Poppins", sans-serif;
  outline: none;

  .material-icons {
    font-size: 2rem;
    vertical-align: sub;
  }

  .material-icons + span {
    margin-left: 5px;
    vertical-align: top;
  }

  &--small {
    font-size: 12px;
    max-width: 300px;
    min-width: 100px;
    height: 30px;
  }

  &--inline {
    display: inline-block;
  }

  &#{&}--pushLeft {
    margin-right: auto;
  }

  &--floatleft {
    float: left;
  }

  &--floatright {
    float: right;
  }

  &--medium {
    min-width: 19rem;
  }

  &--high {
    height: 4rem;
  }

  &--center {
    margin: 10px auto;
    display: block;
  }

  &--uppercase {
    text-transform: uppercase;
  }
  &--disabled {
    opacity: 0.5;
  }

  &--purple {
    color: $white;
    background-color: $secondary-dark;
    border: 1px solid $secondary-dark;

    &:hover {
      background-color: $secondary-dark;
      border: 1px solid $secondary-dark;
    }
  }

  &--purpleReverse {
    color: $secondary-dark;
    background-color: $white;
    border: 1px solid $secondary-dark;

    &:hover {
      background-color: #f9f8f8;
      border: 1px solid $secondary-dark;
    }
  }

  &--gray {
    color: $white;
    background-color: $gray;
    border: 1px solid $gray;

    &:hover {
      background-color: $darker-gray;
      border: 1px solid $darker-gray;
    }
  }

  &--hide {
    display: none;
  }
}
