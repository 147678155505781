$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.c-textInput {
  position: relative;
  padding-top: 0;
  margin-bottom: 0;

  &__activationIndicator {
    background-color: $gray;
    height: 2px;
    width: 100%;
    border-radius: 1px;
  }
  &__label {
    font-size: 14px;
    margin-bottom: 4px;
    font-family: "Poppins";
    color: rgba(0, 0, 0, 0.85);
    cursor: default;
    > .anticon-question-circle {
      float: right;
    }
  }

  &__errorIcon {
    color: #ff4d4f !important;
    -webkit-animation-name: diffZoomIn2 !important;
    animation-name: diffZoomIn2 !important;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 32px;
    height: 16px;
    margin-top: -8px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    visibility: visible;
    -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    pointer-events: none;
  }

  &__input {
    outline: none !important;
    box-shadow: none !important;
  }

  &__error {
    position: absolute;
    line-height: 22px;
    vertical-align: middle;
    font-size: 14px;
    color: #ff4d4f;
  }
  &--error {
    .c-textInput__input {
      border-color: #ff4d4f !important;
    }
  }
  &--required {
    .c-textInput__label {
      &::after {
        display: inline-block;
        margin-left: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
  }
}

//.c-textInput__input:focus + div {
//  & > .c-textInput__label {
//    color: $secondary;
//  }
//  & > .c-textInput__activationIndicator {
//    background-color: $secondary;
//  }
//}

.c-flex--vertical > .c-textInput {
  margin-bottom: 22px;
}
