$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.c-drawer {
  width: 235px;
  background-color: $dark-blue;
  min-height: 100vh;
  float: left;
  padding-top: 16px;
  &__logoContainer {
    width: 235px;
    height: 65px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__spacer {
    width: 235px;
    height: 100px;
  }
  &__logo {
    background-image: url("../../images/logotype_white.png");
    width: 170px;
    height: 50px;
    background-size: contain;
    margin-left: -10px;
  }
}

.c-drawerItem {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: $dark-blue-2;
  }
  &__icon {
    fill: $white;
    color: $white;
    margin-right: 14px;
  }
  &__title {
    font-size: 16px;
    color: $white;
    font-family: "Poppins";
  }
  &--active {
    background-color: $dark-blue-3;
    &:hover {
      background-color: $dark-blue-3;
    }
  }
}
