$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

@import "reset";

@import url("https://fonts.googleapis.com/css?family=Lato:400,700|Poppins:400,500&subset=latin-ext");

html {
  font-size: 2vh;
  font-family: "Lato", sans-serif;
}
body {
  font-family: "Lato", sans-serif;
}

.c-unauthApp {
  background: #0035a5;
  max-width: 100vw;
  min-height: 100vh;
  height: 100%;
  position: relative;
  background: url(../images/reportio_patern_1.png) 0 0 / 350px 350px,
    linear-gradient(315deg, #0019b9 0.57%, #0054ff 100%);
  background-image-opacity: 0.4;
  background-position-x: 0px, initial;
  background-position-y: 0px, initial;
  background-size: 350px 350px, cover;
  background-repeat-x: initial, initial;
  background-repeat-y: initial, initial;
  background-attachment: initial, initial;
  background-origin: initial, initial;
  background-clip: initial, initial;
  background-color: initial;
  color: #ffffff;

  &__loginContainer {
    background-color: #fff;
    width: 25vw;
    height: fit-content;
    width: 500px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-top: 20vh;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 2px;
  }
  &__promoContainer {
    display: flex;
    background-color: $white;
    widht: auto;
    min-height: 100vh;
    background-image: url("../images/promo-1.png");
    background-size: 55vw;
    background-position: center;
    background-repeat: no-repeat;
  }
  .loginButton {
    width: 50%;
    margin: 15px auto 5px;
    background-color: #0053fe;
    &.ant-btn-primary[disabled] {
      color: #00000040;
      background-color: #f5f5f5;
      border-color: #d9d9d9;
    }
  }
}

.c-flex {
  display: flex;
  &--vertical {
    flex-direction: column;
    & > * {
      margin-bottom: 20px;
    }
  }
}

.c-contentContainer {
  padding: 20px 0;
  margin-top: -10px;
  background-color: $whiteish;
  display: grid;
  grid-gap: 20px;
  &--noGap {
    grid-gap: 0;
  }
}

.c-panel {
  background-color: $white;
  padding: 20px;
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
  position: relative;
  &--single {
    grid-template-columns: 1fr;
  }
  &--rightBar {
    padding-right: 60px;
  }
  &--topBar {
    padding-top: 65px;
  }
  &--autoRows {
    grid-template-rows: auto;
  }
}

.c-flexColumns {
  background-color: $white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 50vh;
  > * {
    margin: 10px;
  }
}

.c-addTemplateItemButton {
  width: 65px;
  height: 65px;
  background-color: $primary;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  &__icon {
    fill: $white;
    color: $white;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: $primary-dark;
  }
}

.c-table {
  td,
  th {
    border: 1px solid #ddd;
    height: 30px;
    vertical-align: middle;
    font-size: 12px;
  }
  th {
    background-color: $primary;
    color: $white;
    font-family: "Poppins";
    border-top-color: $primary;
    border-bottom-color: $primary;
    &:first-child {
      border-left-color: $primary;
    }
    &:last-child {
      border-right-color: $primary;
    }
  }
  td {
    background-color: $white;
    padding: 0 10px;
  }
}

.optionLink {
  display: inline-block;
  margin-right: 10px;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.c-currentTemplate {
  &__actionBar {
    width: 100%;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    border-bottom: 2px solid #c1c6d5;
    display: flex;
    justify-content: space-between;
  }
  &__panelTitle {
    line-height: 40px;
    font-size: 21px;
    color: $primary;
  }
  &__actionBarButton {
    width: 42px;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    float: left;
  }

  &__actionBarButtonIcon {
    fill: #6d7ba1;
  }
  &__actionBarButton:hover {
    //background-color: #eee;
    border-bottom: 2px solid #c1c6d5;
    padding-top: 2px;
    .c-currentTemplate__actionBarButtonIcon {
      fill: $primary;
    }
  }
}

.c-authApp {
  display: flex;
  flex-direction: row;
}

.c-helper__spreadEqual {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.c-disabled {
  pointer-events: none;
  opacity: 0.8;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.c-draggable {
  cursor: grab;
}
.c-dragIndicator {
  width: 100%;
  height: 4px;
  background: $primary;
  margin-top: 8px;
  border-radius: 2px;
  margin-bottom: -12px;
  &--top {
    margin-top: -12px;
    margin-bottom: 8px;
    .c-draggable--disabled {
      margin: 0;
    }
  }
  &--disabled {
    background-color: transparent;
    height: 0;
    margin: 0;
  }
}

.Select-menu-outer {
  font-size: 16px;
}

.ant-page-header-heading {
  overflow: visible;
  height: 30px;
}
.ant-page-header-heading-title {
  font-size: 24px;
}

.loadingEmptySpace {
  height: 200px;
}
