$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.c-dataTable {
  td,
  th {
    border: 1px solid #ddd;
    height: 30px;
    vertical-align: middle;
    font-size: 12px;
  }
  th {
    background-color: $secondary-dark;
    color: $white;
    font-family: "Poppins";
    border-top-color: $secondary-dark;
    border-bottom-color: $secondary-dark;
    &:first-child {
      border-left-color: $secondary-dark;
    }
    &:last-child {
      border-right-color: $secondary-dark;
    }
  }
  td {
    background-color: $white;
    padding: 0 10px;
  }
}
