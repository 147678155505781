@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Poppins:400,500&subset=latin-ext);
$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.c-headerItem {
  cursor: default; }
  .c-headerItem--clickable {
    cursor: pointer; }
  .c-headerItem__icon {
    margin: -5px 0;
    fill: white; }

.c-dataTable td,
.c-dataTable th {
  border: 1px solid #ddd;
  height: 30px;
  vertical-align: middle;
  font-size: 12px; }

.c-dataTable th {
  background-color: #1541a1;
  color: #ffffff;
  font-family: "Poppins";
  border-top-color: #1541a1;
  border-bottom-color: #1541a1; }
  .c-dataTable th:first-child {
    border-left-color: #1541a1; }
  .c-dataTable th:last-child {
    border-right-color: #1541a1; }

.c-dataTable td {
  background-color: #ffffff;
  padding: 0 10px; }

.c-button {
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 45px;
  text-align: center;
  padding: 0 1.5rem;
  min-width: 100%;
  background-color: #ff0000;
  font-family: "Poppins", sans-serif;
  outline: none; }
  .c-button .material-icons {
    font-size: 2rem;
    vertical-align: sub; }
  .c-button .material-icons + span {
    margin-left: 5px;
    vertical-align: top; }
  .c-button--small {
    font-size: 12px;
    max-width: 300px;
    min-width: 100px;
    height: 30px; }
  .c-button--inline {
    display: inline-block; }
  .c-button.c-button--pushLeft {
    margin-right: auto; }
  .c-button--floatleft {
    float: left; }
  .c-button--floatright {
    float: right; }
  .c-button--medium {
    min-width: 19rem; }
  .c-button--high {
    height: 4rem; }
  .c-button--center {
    margin: 10px auto;
    display: block; }
  .c-button--uppercase {
    text-transform: uppercase; }
  .c-button--disabled {
    opacity: 0.5; }
  .c-button--purple {
    color: #ffffff;
    background-color: #1541a1;
    border: 1px solid #1541a1; }
    .c-button--purple:hover {
      background-color: #1541a1;
      border: 1px solid #1541a1; }
  .c-button--purpleReverse {
    color: #1541a1;
    background-color: #ffffff;
    border: 1px solid #1541a1; }
    .c-button--purpleReverse:hover {
      background-color: #f9f8f8;
      border: 1px solid #1541a1; }
  .c-button--gray {
    color: #ffffff;
    background-color: #c1c6d5;
    border: 1px solid #c1c6d5; }
    .c-button--gray:hover {
      background-color: #6d7ba1;
      border: 1px solid #6d7ba1; }
  .c-button--hide {
    display: none; }

.c-textInput {
  position: relative;
  padding-top: 0;
  margin-bottom: 0; }
  .c-textInput__activationIndicator {
    background-color: #c1c6d5;
    height: 2px;
    width: 100%;
    border-radius: 1px; }
  .c-textInput__label {
    font-size: 14px;
    margin-bottom: 4px;
    font-family: "Poppins";
    color: rgba(0, 0, 0, 0.85);
    cursor: default; }
    .c-textInput__label > .anticon-question-circle {
      float: right; }
  .c-textInput__errorIcon {
    color: #ff4d4f !important;
    animation-name: diffZoomIn2 !important;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 32px;
    height: 16px;
    margin-top: -8px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    visibility: visible;
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    pointer-events: none; }
  .c-textInput__input {
    outline: none !important;
    box-shadow: none !important; }
  .c-textInput__error {
    position: absolute;
    line-height: 22px;
    vertical-align: middle;
    font-size: 14px;
    color: #ff4d4f; }
  .c-textInput--error .c-textInput__input {
    border-color: #ff4d4f !important; }
  .c-textInput--required .c-textInput__label::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*"; }

.c-flex--vertical > .c-textInput {
  margin-bottom: 22px; }

.c-selectInput {
  position: relative; }
  .c-selectInput__label {
    font-size: 14px;
    font-family: "Poppins";
    color: rgba(0, 0, 0, 0.85);
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }

.Select {
  position: relative; }
  .Select-control {
    border: none;
    padding-bottom: 10px;
    margin-top: 14px;
    margin-bottom: -1px;
    outline: none;
    box-shadow: none !important; }
    .Select-control:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #c1c6d5;
      border-radius: 1px;
      position: absolute;
      bottom: 1px;
      left: 0; }
  .Select-placeholder {
    font-size: 16px;
    color: #c1c6d5;
    font-family: "Lato"; }
  .Select-value {
    padding-left: 0 !important; }
    .Select-value-label {
      font-size: 16px;
      color: #2d2d2d;
      display: inline-block;
      margin-top: 7px; }
  .Select-input {
    font-size: 16px;
    color: #c1c6d5;
    font-family: "Lato";
    margin-top: 7px;
    margin-bottom: -7px;
    margin-left: -10px; }

.Select--multi .Select-value {
  background-color: #1890ff;
  color: #ffffff;
  line-height: 20px;
  margin-top: 9px;
  margin-bottom: -3px; }
  .Select--multi .Select-value-label {
    color: #ffffff;
    margin-top: 0;
    font-family: Poppins;
    font-size: 12px; }

.c-selectInput .ant-select {
  width: 100%;
  margin-top: 20px;
  height: 32px; }

.c-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; }
  .c-toggle input[type="checkbox"] {
    display: none; }
    .c-toggle input[type="checkbox"]:checked ~ .c-toggle__toggle {
      background: #1541a1;
      left: 22.72727px;
      transition: 0.5s; }
    .c-toggle input[type="checkbox"]:checked ~ .c-toggle__switch {
      background: #c6d9ff;
      transition: 0.5s; }
  .c-toggle__switch {
    display: block;
    width: 50px;
    height: 22.72727px;
    background: #c6d9ff;
    border-radius: 11.36364px;
    position: absolute;
    top: 0;
    transition: 0.5s;
    cursor: pointer; }
  .c-toggle__toggle {
    height: 27.27273px;
    width: 27.27273px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: -2.27273px;
    left: -2.27273px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    cursor: pointer; }
  .c-toggle__container {
    display: inline-block;
    margin: 9.54545px;
    margin: 5.68182px;
    width: 50px;
    height: 22.72727px;
    text-align: center;
    position: relative; }
  .c-toggle__label {
    font-size: 18px;
    color: #6d7ba1;
    cursor: default; }
    .c-toggle__label:hover {
      color: #7e72b1; }

.modalShadow {
  display: none; }
  .modalShadow--visible {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9995; }

.kmodal {
  background-color: white;
  position: fixed;
  top: 6vh;
  opacity: 1;
  transition: top 0.7s, opacity 0.5s;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 440px;
  min-height: 100px;
  z-index: 9999;
  border-radius: 3px;
  overflow: hidden; }
  .kmodal--thin .kmodal {
    width: 360px; }
  .kmodal__header {
    width: 100%;
    min-height: 50px;
    padding: 14px 20px;
    font-family: Poppins;
    border-bottom: 1px solid #ddd;
    background-color: #031b4d;
    color: #ffffff;
    font-size: 20px; }
    .kmodal__header--closeButton {
      padding-right: 40px; }
  .kmodal__closeIcon {
    position: absolute;
    right: 13px;
    top: 13px;
    float: right;
    fill: #ffffff; }
    .kmodal__closeIcon:hover {
      color: #ddd;
      cursor: pointer; }
  .kmodal__body {
    width: 100%;
    max-height: 70vh;
    overflow: auto;
    padding: 12px 20px; }
  .kmodal__footer {
    width: 100%;
    padding: 12px 20px 16px;
    overflow: auto; }
  .kmodal__wrapper--entering .kmodal {
    top: 0;
    opacity: 0;
    transition: top 0.3s, opacity 0.2s; }
  .kmodal__wrapper--step .kmodal {
    width: 530px; }
  .kmodal__repeatButton {
    font-size: 18px;
    color: #0a1b3d; }
    .kmodal__repeatButton:hover {
      color: #031b4d; }

.c-link {
  cursor: pointer;
  margin-bottom: -2px;
  min-width: 60px; }
  .c-link__title {
    line-height: 20px;
    vertical-align: bottom;
    margin-left: 5px; }
  .c-link:hover .c-link__title {
    color: #031b4d; }
  .c-link:hover .c-link__icon {
    fill: #031b4d; }

.c-label {
  display: inline-block;
  background-color: #1541a1;
  margin-right: 10px;
  margin-top: 5px;
  padding: 3px 7px;
  border-radius: 2px; }
  .c-label__title {
    color: #ffffff; }
  .c-label__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

.c-searchBox {
  position: relative;
  display: flex; }
  .c-searchBox__input {
    box-sizing: border-box;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 5px 7px; }
  .c-searchBox__icon {
    width: 22px;
    position: absolute;
    right: 5px;
    top: 4px; }

.c-stepRules {
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 0; }
  .c-stepRules__valueContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0; }
  .c-stepRules__rulesText {
    font-size: 18px;
    line-height: 22px;
    color: #6d7ba1;
    margin: 8px 0; }
  .c-stepRules__container {
    display: inline-block;
    margin: 9.54545px;
    margin: 5.68182px;
    width: 50px;
    height: 22.72727px;
    text-align: center;
    position: relative; }
  .c-stepRules__label {
    font-size: 18px;
    color: #6d7ba1;
    cursor: default; }
    .c-stepRules__label:hover {
      color: #7e72b1; }

.rdrMonths {
  font-size: 13px; }

.rdrDateDisplayWrapper {
  display: none; }

.rdrMonthAndYearPickers {
  display: none; }

.rdrMonths {
  margin-top: -53px; }

.rdrMonthAndYearWrapper {
  padding-top: 0px;
  margin-top: -10px; }

.rdrMonthName {
  text-align: center;
  text-transform: capitalize;
  font-size: 18px; }

.rdrPprevButton {
  margin-left: 20px !important; }
  .rdrPprevButton i {
    margin-left: 8px !important; }

.rdrNextButton {
  margin-right: 20px !important; }
  .rdrNextButton i {
    margin-left: 6px !important; }

.rdrStaticRanges {
  margin-right: 10px;
  margin-top: 43px; }

.rdrStaticRange {
  border-radius: 3px; }

.rdrDefinedRangesWrapper {
  width: 150px; }

.c-dataRange {
  display: inline-block;
  position: relative;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  background-color: white; }
  .c-dataRange__popup {
    position: absolute;
    background: white;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #ddd;
    right: 0;
    top: 35px;
    z-index: 10; }
  .c-dataRange__button {
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: space-around;
    padding: 0 7px;
    cursor: pointer; }
  .c-dataRange__icon {
    margin-right: 5px;
    height: 30px !important; }

.c-scoreNamesInput__radio.ant-radio-group {
  margin-bottom: 7px; }

.ant-input-group.c-scoreNamesInput__inputRow {
  margin-bottom: 7px; }
  .ant-input-group.c-scoreNamesInput__inputRow .c-scoreNamesInput__nameInput.ant-input {
    width: calc(100% - 107px); }
  .ant-input-group.c-scoreNamesInput__inputRow .c-scoreNamesInput__scoreInput.ant-input-number {
    width: 100px;
    margin-right: 7px !important; }

.c-unAuthAppBox {
  margin-top: 25vh;
  background-color: #ffffff;
  width: 500px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 3px; }
  @media (max-width: 768px) {
    .c-unAuthAppBox {
      margin-top: 0;
      margin: 20px;
      padding: 40px 14px;
      justify-content: flex-start; } }
  .c-unAuthAppBox > .c-textInput {
    margin-bottom: 22px; }

.c-confirmModal__content {
  margin-top: 10px;
  font-size: 16px; }

.c-login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; }
  .c-login__container {
    margin-top: 25vh;
    background-color: #ffffff;
    width: 500px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 40px;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px; }
    @media (max-width: 768px) {
      .c-login__container {
        margin-top: 0;
        margin: 20px;
        padding: 40px 14px;
        justify-content: flex-start; } }
    .c-login__container > .c-textInput {
      margin-bottom: 22px; }
  .c-login__title {
    color: #2d2d2d;
    font-size: 28px;
    margin-bottom: 50px;
    font-weight: 700; }
  .c-login__registerContainer {
    margin: 20px 0 0;
    font-size: 14px;
    text-align: center;
    line-height: 1.4; }
  .c-login__noAccountText {
    color: #6d7ba1; }
  .c-login__passwordResetContainer {
    margin: 20px 0 0;
    font-size: 14px;
    text-align: center; }
    .c-login__passwordResetContainer > a {
      color: #6d7ba1; }
  .c-login__errorText {
    color: #ff4d4f;
    text-align: center;
    width: 100%;
    font-size: 14px; }

.c-register {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; }
  .c-register__container {
    margin-top: 5vh;
    margin-bottom: 5vh;
    background-color: #ffffff;
    width: 500px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 40px;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px; }
    @media (max-width: 768px) {
      .c-register__container {
        margin-top: 0;
        margin: 20px;
        padding: 40px 14px;
        justify-content: flex-start; } }
    .c-register__container > .c-textInput {
      margin-bottom: 22px; }
  .c-register__title {
    color: #2d2d2d;
    font-size: 28px;
    margin-bottom: 50px;
    font-weight: 700; }
  .c-register__registerContainer {
    margin: 20px 0 0;
    font-size: 14px;
    text-align: center;
    line-height: 1.4; }
  .c-register__noAccountText {
    color: #6d7ba1; }

.c-passwordReset__infoText {
  font-size: 14px;
  color: #0a1b3d;
  opacity: 0.85;
  margin-bottom: 20px;
  line-height: 1.2; }

.c-passwordReset__title {
  color: #2d2d2d;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 700; }

.c-login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; }
  .c-login__container {
    margin-top: 25vh;
    background-color: #ffffff;
    width: 500px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 40px;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px; }
    @media (max-width: 768px) {
      .c-login__container {
        margin-top: 0;
        margin: 20px;
        padding: 40px 14px;
        justify-content: flex-start; } }
    .c-login__container > .c-textInput {
      margin-bottom: 22px; }

.c-passwordReset__infoText {
  font-size: 14px;
  color: #0a1b3d;
  opacity: 0.85;
  margin-bottom: 20px;
  line-height: 1.2; }

.c-passwordReset__title {
  color: #2d2d2d;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 700; }

.c-login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; }
  .c-login__container {
    margin-top: 25vh;
    background-color: #ffffff;
    width: 500px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 40px;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 3px; }
    @media (max-width: 768px) {
      .c-login__container {
        margin-top: 0;
        margin: 20px;
        padding: 40px 14px;
        justify-content: flex-start; } }
    .c-login__container > .c-textInput {
      margin-bottom: 22px; }

.c-drawer {
  width: 235px;
  background-color: #0018a5;
  min-height: 100vh;
  float: left;
  padding-top: 16px; }
  .c-drawer__logoContainer {
    width: 235px;
    height: 65px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .c-drawer__spacer {
    width: 235px;
    height: 100px; }
  .c-drawer__logo {
    background-image: url(/static/media/logotype_white.d4606fec.png);
    width: 170px;
    height: 50px;
    background-size: contain;
    margin-left: -10px; }

.c-drawerItem {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 30px;
  box-sizing: border-box;
  cursor: pointer; }
  .c-drawerItem:hover {
    background-color: #00179b; }
  .c-drawerItem__icon {
    fill: #ffffff;
    color: #ffffff;
    margin-right: 14px; }
  .c-drawerItem__title {
    font-size: 16px;
    color: #ffffff;
    font-family: "Poppins"; }
  .c-drawerItem--active {
    background-color: #001682; }
    .c-drawerItem--active:hover {
      background-color: #001682; }

.c-content {
  display: block;
  background-color: #f8f9ff;
  width: auto;
  min-height: 100vh;
  overflow: hidden;
  flex: 1 1; }


.c-topBar {
  width: 100%;
  height: 50px;
  padding: 20px 20px 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between; }
  .c-topBar__item {
    display: flex; }
    .c-topBar__item > * {
      margin: 0 5px; }
    .c-topBar__item :first-child {
      margin-left: 0; }
    .c-topBar__item :last-child {
      margin-right: 0; }

.c-stepRulesModal .kmodal {
  width: 575px; }

.c-stepRulesModal .Select-control {
  margin-top: 0; }

.c-stepRulesModal .Select-clear {
  display: none; }

.c-stepRulesModal .Select-value {
  text-align: center;
  padding-right: 0 !important; }

.c-stepRulesModal .Select-value-label {
  font-size: 21px; }

.c-stepRulesModal__contentWrapper {
  min-height: 400px;
  padding-top: 10px; }

.c-stepRulesModal__rule {
  height: 52px;
  border-bottom: 1px solid #c1c6d5; }

.c-stepRulesModal__ruleInputs {
  display: flex;
  justify-content: space-between;
  height: 32px;
  width: 100%;
  align-items: center; }

.c-stepRulesModal__stepSelect {
  width: 80px;
  margin: 0 5px 0 10px !important;
  flex-shrink: 0; }

.c-stepRulesModal__ruleSelect {
  width: 100px;
  margin: 0 5px !important;
  flex-shrink: 0; }

.c-stepRulesModal__valueInput {
  height: 32px;
  margin-bottom: -8px;
  border: none;
  border-bottom: 2px solid #c1c6d5;
  font-size: 21px;
  width: 180px;
  margin: 0 5px !important;
  flex-grow: 1; }

.c-stepRulesModal__label {
  font-size: 18px;
  margin-bottom: -5px; }

.c-stepRulesModal__newRuleButtonContainer {
  display: flex;
  justify-content: center; }

.c-stepRulesModal__newRuleButton {
  display: inline-block;
  background-color: #031b4d;
  height: 48px;
  width: 48px;
  text-align: center;
  line-height: 48px;
  border-radius: 50%;
  color: white;
  font-weight: 600;
  cursor: pointer;
  font-size: 31px;
  padding-top: 2px; }

.c-stepRulesModal__deleteIcon {
  fill: #666;
  flex-shrink: 0;
  cursor: pointer; }
  .c-stepRulesModal__deleteIcon:hover {
    fill: #031b4d; }

.stepOption {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .stepOption__label {
    font-size: 18px;
    color: #6d7ba1;
    cursor: default; }

.cc-stepRulesModal .kmodal {
  width: 575px; }

.cc-stepRulesModal .Select-control {
  margin-top: 0; }

.cc-stepRulesModal .Select-clear {
  display: none; }

.cc-stepRulesModal .Select-value {
  text-align: center;
  padding-right: 0 !important; }

.cc-stepRulesModal .Select-value-label {
  font-size: 21px; }

.cc-stepRulesModal__contentWrapper {
  min-height: 400px; }

.cc-stepRulesModal__rule {
  display: flex;
  justify-content: space-between;
  height: 55px;
  align-items: center;
  border-bottom: 1px solid #c1c6d5; }

.cc-stepRulesModal__stepSelect {
  width: 80px;
  margin: 0 5px 0 10px !important; }

.cc-stepRulesModal__ruleSelect {
  width: 150px;
  margin: 0 5px !important; }

.cc-stepRulesModal__valueInput {
  height: 32px;
  margin-bottom: -8px;
  border: none;
  border-bottom: 2px solid #c1c6d5;
  font-size: 21px;
  width: 180px;
  margin: 0 5px !important; }

.cc-stepRulesModal__label {
  font-size: 18px; }

.cc-stepRulesModal__newRuleButtonContainer {
  display: flex;
  justify-content: center; }

.cc-stepRulesModal__newRuleButton {
  display: inline-block;
  background-color: #031b4d;
  height: 48px;
  width: 48px;
  text-align: center;
  line-height: 48px;
  border-radius: 50%;
  color: white;
  font-weight: 600;
  cursor: pointer;
  font-size: 31px; }

.cc-stepRulesModal__deleteIcon {
  fill: #666;
  cursor: pointer; }
  .cc-stepRulesModal__deleteIcon:hover {
    fill: #031b4d; }

.stepScoreRule {
  padding: 10px 14px;
  background-color: #f8f9ff;
  border-radius: 3px;
  position: relative; }
  .stepScoreRule__label {
    font-size: 18px;
    color: #031b4d;
    display: inline-block;
    vertical-align: center;
    line-height: 40px; }
  .stepScoreRule__valueInput {
    border: 1px solid #ddd;
    line-height: 26px;
    border-radius: 3px;
    padding: 3px 7px;
    vertical-align: middle;
    box-sizing: border-box;
    outline: none;
    color: #333333;
    font-size: 16px;
    width: 150px; }
  .stepScoreRule__deleteIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    display: inline-block;
    fill: #666; }
  .stepScoreRule .Select {
    position: relative;
    display: inline-block;
    width: 100px;
    vertical-align: middle;
    margin: 0 5px; }
  .stepScoreRule .Select-value {
    text-align: left;
    margin-left: 10px; }
  .stepScoreRule .Select-control {
    height: 34px;
    border-radius: 3px;
    padding: 0;
    border: 1px solid #ddd; }
    .stepScoreRule .Select-control:after {
      display: none; }
  .stepScoreRule .Select-value-label {
    line-height: 34px;
    margin: 0;
    vertical-align: middle;
    font-size: 16px; }
  .stepScoreRule .Select-arrow-zone {
    padding-top: 0;
    padding-bottom: 5px; }
  .stepScoreRule .Select-input {
    margin-top: 0; }
  .stepScoreRule .Select.is-focused:not(.is-open) > .Select-control {
    border-color: #aaa; }
  .stepScoreRule .Select--width2 {
    width: 150px; }

.c-reportQuestion__container {
  margin-bottom: 20px; }
  .c-reportQuestion__container.c-reportQuestion--skipped {
    opacity: 0.6;
    filter: grayscale(100%); }

.c-reportQuestion__inputContainer {
  margin-top: 15px; }

.c-reportQuestion__title {
  margin-bottom: 7px; }

.c-reportQuestion__comment {
  margin-bottom: 7px;
  font-size: 14px;
  color: #6d7ba1; }

.c-reportQuestion__requiredStar {
  color: #ff4d4f;
  margin-left: 5px; }

.c-reportQuestion__errorMessage {
  color: #ff4d4f;
  font-size: 14px; }

.c-reportQuestion__skipButton {
  color: #6d7ba1;
  font-size: 12px;
  margin-left: 7px;
  cursor: pointer; }

.c-reportView__container {
  padding: 12px 24px; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-size: 2vh;
  font-family: "Lato", sans-serif; }

body {
  font-family: "Lato", sans-serif; }

.c-unauthApp {
  background: #0035a5;
  max-width: 100vw;
  min-height: 100vh;
  height: 100%;
  position: relative;
  background: url(/static/media/reportio_patern_1.a9476bae.png) 0 0/350px 350px, linear-gradient(315deg, #0019b9 0.57%, #0054ff 100%);
  background-image-opacity: 0.4;
  background-position-x: 0px, initial;
  background-position-y: 0px, initial;
  background-size: 350px 350px, cover;
  background-repeat-x: initial, initial;
  background-repeat-y: initial, initial;
  background-attachment: initial, initial;
  background-origin: initial, initial;
  background-clip: initial, initial;
  background-color: initial;
  color: #ffffff; }
  .c-unauthApp__loginContainer {
    background-color: #fff;
    width: 25vw;
    height: -moz-fit-content;
    height: fit-content;
    width: 500px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-top: 20vh;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 2px; }
  .c-unauthApp__promoContainer {
    display: flex;
    background-color: #ffffff;
    widht: auto;
    min-height: 100vh;
    background-image: url(/static/media/promo-1.7787b4a9.png);
    background-size: 55vw;
    background-position: center;
    background-repeat: no-repeat; }
  .c-unauthApp .loginButton {
    width: 50%;
    margin: 15px auto 5px;
    background-color: #0053fe; }
    .c-unauthApp .loginButton.ant-btn-primary[disabled] {
      color: #00000040;
      background-color: #f5f5f5;
      border-color: #d9d9d9; }

.c-flex {
  display: flex; }
  .c-flex--vertical {
    flex-direction: column; }
    .c-flex--vertical > * {
      margin-bottom: 20px; }

.c-contentContainer {
  padding: 20px 0;
  margin-top: -10px;
  background-color: #f8f9ff;
  display: grid;
  grid-gap: 20px; }
  .c-contentContainer--noGap {
    grid-gap: 0; }

.c-panel {
  background-color: #ffffff;
  padding: 20px;
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
  position: relative; }
  .c-panel--single {
    grid-template-columns: 1fr; }
  .c-panel--rightBar {
    padding-right: 60px; }
  .c-panel--topBar {
    padding-top: 65px; }
  .c-panel--autoRows {
    grid-template-rows: auto; }

.c-flexColumns {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 50vh; }
  .c-flexColumns > * {
    margin: 10px; }

.c-addTemplateItemButton {
  width: 65px;
  height: 65px;
  background-color: #031b4d;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer; }
  .c-addTemplateItemButton__icon {
    fill: #ffffff;
    color: #ffffff; }
  .c-addTemplateItemButton__container {
    display: flex;
    align-items: center;
    justify-content: center; }
  .c-addTemplateItemButton:hover {
    background-color: #0a1b3d; }

.c-table td,
.c-table th {
  border: 1px solid #ddd;
  height: 30px;
  vertical-align: middle;
  font-size: 12px; }

.c-table th {
  background-color: #031b4d;
  color: #ffffff;
  font-family: "Poppins";
  border-top-color: #031b4d;
  border-bottom-color: #031b4d; }
  .c-table th:first-child {
    border-left-color: #031b4d; }
  .c-table th:last-child {
    border-right-color: #031b4d; }

.c-table td {
  background-color: #ffffff;
  padding: 0 10px; }

.optionLink {
  display: inline-block;
  margin-right: 10px; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.c-currentTemplate__actionBar {
  width: 100%;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom: 2px solid #c1c6d5;
  display: flex;
  justify-content: space-between; }

.c-currentTemplate__panelTitle {
  line-height: 40px;
  font-size: 21px;
  color: #031b4d; }

.c-currentTemplate__actionBarButton {
  width: 42px;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: left; }

.c-currentTemplate__actionBarButtonIcon {
  fill: #6d7ba1; }

.c-currentTemplate__actionBarButton:hover {
  border-bottom: 2px solid #c1c6d5;
  padding-top: 2px; }
  .c-currentTemplate__actionBarButton:hover .c-currentTemplate__actionBarButtonIcon {
    fill: #031b4d; }

.c-authApp {
  display: flex;
  flex-direction: row; }

.c-helper__spreadEqual {
  display: flex;
  justify-content: space-around;
  align-items: center; }

.c-disabled {
  pointer-events: none;
  opacity: 0.8;
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%); }

.c-draggable {
  cursor: grab; }

.c-dragIndicator {
  width: 100%;
  height: 4px;
  background: #031b4d;
  margin-top: 8px;
  border-radius: 2px;
  margin-bottom: -12px; }
  .c-dragIndicator--top {
    margin-top: -12px;
    margin-bottom: 8px; }
    .c-dragIndicator--top .c-draggable--disabled {
      margin: 0; }
  .c-dragIndicator--disabled {
    background-color: transparent;
    height: 0;
    margin: 0; }

.Select-menu-outer {
  font-size: 16px; }

.ant-page-header-heading {
  overflow: visible;
  height: 30px; }

.ant-page-header-heading-title {
  font-size: 24px; }

.loadingEmptySpace {
  height: 200px; }

td.ant-table-column-sort {
  background: inherit; }

.ant-empty {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center; }

.ant-input-password .ant-input-suffix {
  padding-top: 2px;
  margin-bottom: -2px; }

.ant-page-header-heading-left {
  margin: 0; }

