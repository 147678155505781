$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.c-reportQuestion {
  &__container {
    margin-bottom: 20px;
    &.c-reportQuestion--skipped {
      opacity: 0.6;
      filter: grayscale(100%);
    }
  }
  &__inputContainer {
    margin-top: 15px;
  }
  &__title {
    margin-bottom: 7px;
  }
  &__comment {
    margin-bottom: 7px;
    font-size: 14px;
    color: $darker-gray;
  }
  &__requiredStar {
    color: $light-red;
    margin-left: 5px;
  }
  &__errorMessage {
    color: $light-red;
    font-size: 14px;
  }
  &__skipButton {
    color: $darker-gray;
    font-size: 12px;
    margin-left: 7px;
    cursor: pointer;
  }
}
