$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.rdrMonths {
  font-size: 13px;
}
.rdrDateDisplayWrapper {
  display: none;
}
.rdrMonthAndYearPickers {
  display: none;
}
.rdrMonths {
  margin-top: -53px;
}
.rdrMonthAndYearWrapper {
  padding-top: 0px;
  margin-top: -10px;
}
.rdrMonthName {
  text-align: center;
  text-transform: capitalize;
  font-size: 18px;
}
.rdrPprevButton {
  margin-left: 20px !important;
  i {
    margin-left: 8px !important;
  }
}
.rdrNextButton {
  margin-right: 20px !important;
  i {
    margin-left: 6px !important;
  }
}
.rdrStaticRanges {
  margin-right: 10px;
  margin-top: 43px;
}
.rdrStaticRange {
  border-radius: 3px;
}
.rdrDefinedRangesWrapper {
  width: 150px;
}
.c-dataRange {
  display: inline-block;
  position: relative;
  height: 32px;
  //width: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  background-color: white;

  &__popup {
    position: absolute;
    background: white;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #ddd;
    right: 0;
    top: 35px;
    z-index: 10;
  }
  &__button {
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: space-around;
    padding: 0 7px;
    cursor: pointer;
  }
  &__icon {
    margin-right: 5px;
    height: 30px !important;
  }
}
