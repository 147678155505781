$red: #ff0000;
$light-red: #ff4d4f;

$blue: #0053fe;
$dark-blue: #0018a5;
$dark-blue-2: #00179b;
$dark-blue-3: #001682;

$primary: #031b4d;
$primary-dark: #0a1b3d;
$primary-darker: #2d2d2d;
$primary-light: #374870;

$secondary: #1a40dd;
$secondary-dark: #1541a1;
$secondary-darker: #1a40dd;
$secondary-pale: #c6d9ff;

$purple: #4125b9;
$dark-purple: #341aa6;
$darker-purple: #19306c;

$white: #ffffff;
$whiteish: #f8f9ff;
$gray: #c1c6d5;
$darker-gray: #6d7ba1;
$light-gray: #c1c5d3;

$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

.c-stepRulesModal {
  .kmodal {
    width: 575px;
  }
  .Select-control {
    margin-top: 0;
  }
  .Select-clear {
    display: none;
  }
  .Select-value {
    text-align: center;
    padding-right: 0 !important;
  }
  .Select-value-label {
    font-size: 21px;
  }
  &__contentWrapper {
    min-height: 400px;
    padding-top: 10px;
  }
  &__rule {
    height: 52px;
    border-bottom: 1px solid $gray;
  }
  &__ruleInputs {
    display: flex;
    justify-content: space-between;
    height: 32px;
    width: 100%;
    align-items: center;
  }
  &__stepSelect {
    width: 80px;
    margin: 0 5px 0 10px !important;
    flex-shrink: 0;
  }
  &__ruleSelect {
    width: 100px;
    margin: 0 5px !important;
    flex-shrink: 0;
  }
  &__valueInput {
    height: 32px;
    margin-bottom: -8px;
    border: none;
    border-bottom: 2px solid #c1c6d5;
    font-size: 21px;
    width: 180px;
    margin: 0 5px !important;
    flex-grow: 1;
  }
  &__label {
    font-size: 18px;
    margin-bottom: -5px;
  }
  &__newRuleButtonContainer {
    display: flex;
    justify-content: center;
  }
  &__newRuleButton {
    display: inline-block;
    background-color: $primary;
    height: 48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    cursor: pointer;
    font-size: 31px;
    padding-top: 2px;
  }
  &__deleteIcon {
    fill: #666;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
      fill: $primary;
    }
  }
}

.stepOption {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__label {
    font-size: 18px;
    color: #6d7ba1;
    cursor: default;
  }
}
